@import '../../../assets/styles/variables';

.UsersTable {
  width: 100%;

  thead {
    height: 50px;

    tr {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
  }

  tbody {
    tr {
      td {
        padding: 28px 56px;
        padding-left: 36px;
        font-family: 'Inter';
        font-size: 18px;
        font-weight: 400;
        line-height: 125%;
        color: $text;
        text-align: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      }
    }
  }
}
