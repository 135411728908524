@import '../../assets/styles/mixins';

.SplitTemplate {
  min-height: 100vh;
  display: flex;

  .leftSide {
    width: 50%;
    padding: 40px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include onMobile {
      width: 100%;
      padding: 40px 36px;
    }
  }

  .rightSide {
    width: 50%;
    display: flex;
    justify-content: center;
    position: relative;
    background: #000000;

    @include onMobile {
      display: none;
    }

    img {
      max-height: 100vh;
      width: 100%;
    }
  }
}