@import '../../../assets/styles/variables';

.VideosReport {
  table {
    width: 100%;
    // table-layout: fixed;
    thead {
      height: 50px;

      tr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      }
    }

    tbody {
      tr {
        height: 80px;
        td {
          padding: 12px 56px;
          padding-left: 36px;
          font-family: 'Inter';
          font-size: 18px;
          font-weight: 400;
          line-height: 125%;
          color: $text;
          text-align: center;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);

          &:first-child {
            text-align: left;
          }
        }
      }
    }
  }
}
